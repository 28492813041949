<template>
  <suy-table v-model:columns="columns">
    <template #search>
      <div style="display:flex;justify-content: flex-end;">
        <!-- <span style="cursor: pointer;"><span style="font-size:20px;">{{'←'}}</span>配置新页面</span> -->
        <a-button type="primary" @click="newPage">配置新页面</a-button>
      </div>
    </template>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="dataSoure" :bordered="false" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #edit="{record}">
        <div>
          <img style="width:14px;height:14px;margin-top:-3px" src="@/assets/images/set.png" />
          <a @click="setUp(record)"> 子页面配置</a>
          <a-divider type="vertical" />
          <img style="width:14px;height:14px;margin-top:-3px" src="@/assets/images/edit.png" />
          <a @click="edit(record)"> 编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认删除" ok-text="确认" cancel-text="取消" @confirm="deleteBtn(record)">
            <ClearOutlined style="color:#f00" />
            <a style="color:#f00"> 删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </suy-table>
  <a-modal v-model:visible="editStatus" title="编辑" @ok="confirm">
    <div>
      <a-textarea v-model:value="editValue" :rows="4" />
    </div>
  </a-modal>
  <a-modal v-model:visible="visible" :title="channel + '渠道 子页面配置'" footer="" width="80%">
    <a-table :columns="childColums" :row-selection="rowSelect" :row-key="record => record.id" :data-source="childDataSoure" :bordered="false" :pagination="childPagination" :loading="childLoading" @change="childChange">
      <template #pageName="{record}">
        <div @dblclick="editDetail(record,'pageName')" style="cursor: pointer;">
          <span>{{record.pageName}}</span>
        </div>
      </template>
      <template #pageUrl="{record}">
        <div @dblclick="editDetail(record,'pageUrl')" style="cursor: pointer;">
          <span>{{record.pageUrl}}</span>
        </div>
      </template>
      <template #detailPageDesc="{record}">
        <div @dblclick="editDetail(record,'detailPageDesc')" style="cursor: pointer;">
          <span>{{record.detailPageDesc}}</span>
        </div>
      </template>
      <template #keyWorld="{record}">
        <div @dblclick="editDetail(record,'keyWorld')" style="cursor: pointer;">
          <span>{{record.keyWorld}}</span>
        </div>
      </template>
      <template #clickType="{record}">
        <div @dblclick="editDetail(record,'clickType')" style="cursor: pointer;">
          <span>{{record.clickType}}</span>
        </div>
      </template>
      <template #monitorCode="{record}">
        <div @dblclick="editDetail(record,'monitorCode')" style="cursor: pointer;">
          <a-typography-text style="width:150px;overflow: hidden;text-overflow: ellipsis;" :ellipsis="true" :content="record.monitorCode" />
        </div>
      </template>
      <template #returnConfig="{record}">
        <div @dblclick="editDetail(record,'returnConfig')" style="cursor: pointer;">
          <a-typography-text style="width:150px;overflow: hidden;text-overflow: ellipsis;" :ellipsis="true" :content="record.returnConfig" />
        </div>
      </template>
      <template #personalInfoAuth="{record}">
        <a-switch checked-children="显示" un-checked-children="隐藏" v-model:checked="record.personalInfoAuth.value" @change="personalChange(record)" :checkedValue="1" :unCheckedValue="0" />
      </template>
      <template #state="{record}">
        <a-switch checked-children="开" un-checked-children="关" v-model:checked="record.state" :checkedValue="1" :unCheckedValue="0" @change="useStatusChange(record)" />
      </template>
      <template #operation="{record}">
        <div>
          <a-popconfirm title="确认生成" ok-text="确认" cancel-text="取消" @confirm="generate(record)">
            <a>生成</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm title="确认保存" ok-text="确认" cancel-text="取消" @confirm="save(record)">
            <a>保存</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <a-popconfirm title="确认生成" ok-text="确认" cancel-text="取消" @confirm="batchGener">
      <a-button type="primary">批量生成</a-button>
    </a-popconfirm>
    <a-button type="primary" style="margin-left:15px" @click="onUpdate" :loading="childLoading">一键更新</a-button>
  </a-modal>
  <!-- 新增配置 -->
  <a-modal v-model:visible="addStatus" footer="">
    <template #title>
      <div>
        <span style="font-weight: bold;">请选择要配置的渠道</span>
      </div>
    </template>
    <div v-show="!channelLoading">
      <a-radio-group v-model:value="channel">
        <a-radio v-for="item in channelList" :key="item.id" :value="item.id">{{item.name}}</a-radio>
      </a-radio-group>
    </div>
    <div v-show="channelLoading" style="margin:20px 0;display:flex;justify-content:center">
      <a-spin size="large" />
    </div>
    <div style="margin-top:20px">
      <a-button style="width:100%" type="primary" @click="addChannel" :loading="addLoading">确定</a-button>
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import SuyTable from '@/components/SuyTable'
import { ClearOutlined } from '@ant-design/icons-vue'
import { getPageList, detailList, saveDetail, addPage, generatePage, editSwitch, deletePage, update } from '@/api/marketing/modList'
import { getChannelList } from '@/api/global.js'
import { message } from 'ant-design-vue'

export default {
  components: {
    SuyTable,
    ClearOutlined
  },
  setup () {
    const router = useRouter()
    const state = reactive({
      loading: false,
      childLoading: false,
      addStatus: false,
      channelLoading: false,
      visible: false,
      editStatus: false,
      addLoading: false,
      useStatus: true,
      channel: null,
      key: '',
      editValue: '',
      channer: '',
      id: router.currentRoute.value.params.id,
      pageId: '',
      channelList: [],
      dataSoure: [],
      childDataSoure: [],
      batch: [],
      data: {},
      childPagination: {},
      columns: [
        {
          title: '渠道分类',
          dataIndex: 'channel.label'
        },
        {
          title: '子页面数',
          dataIndex: 'childPage'
        },
        {
          title: '配置时间',
          dataIndex: 'updateTime'
        },
        {
          title: '操作人',
          dataIndex: 'operator'
        },
        {
          title: '投放情况',
          dataIndex: 'status',
          slots: {
            customRender: 'status'
          }
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '操作',
          dataIndex: 'edit',
          width: '16%',
          slots: {
            customRender: 'edit'
          }
        }
      ],
      childColums: [
        {
          title: '渠道分类',
          dataIndex: 'channel.label'
        },
        {
          title: '页面名称',
          dataIndex: 'pageName',
          slots: {
            customRender: 'pageName'
          }
        },
        {
          title: '页面地址',
          dataIndex: 'pageUrl',
          slots: {
            customRender: 'pageUrl'
          }
        },
        {
          title: '页面描述',
          dataIndex: 'detailPageDesc',
          slots: {
            customRender: 'detailPageDesc'
          }
        },
        {
          title: '关键字设置',
          dataIndex: 'keyWorld',
          slots: {
            customRender: 'keyWorld'
          }
        },
        {
          title: '点击类型',
          dataIndex: 'clickType',
          slots: {
            customRender: 'clickType'
          }
        },
        {
          title: '监控代码',
          dataIndex: 'monitorCode',
          slots: {
            customRender: 'monitorCode'
          }
        },
        {
          title: '回传代码',
          dataIndex: 'returnConfig',
          slots: {
            customRender: 'returnConfig'
          }
        },
        {
          title: '个人信息授权',
          dataIndex: 'personalInfoAuth',
          slots: {
            customRender: 'personalInfoAuth'
          }
        },
        {
          title: '投放情况',
          dataIndex: 'state',
          slots: {
            customRender: 'state'
          }
        },
        {
          title: '最近配置时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      rowSelect: {
        onChange: (datakey, selectedRows) => {
          state.batch = datakey
        },
        getCheckboxProps: record => ({
          disabled: false
        })
      }
    })
    onMounted(() => {
      loadData()
    })
    const loadData = () => {
      state.loading = true
      getPageList({ templateId: state.id })
        .then(res => {
          if (res.code === 10000) {
            state.dataSoure = res.data
          }
        }).catch(err => console.log(err))
        .finally(() => { state.loading = false })
    }
    const newPage = () => {
      state.addStatus = true
      if (state.channelList.length <= 0) {
        state.channelLoading = true
        getChannelList({ channelLevel: 1, isEnable: 1 })
          .then(res => {
            if (res.code === 10000) {
              state.channelList = res.data
              state.channelLoading = false
            }
          }).catch(err => console.log(err))
      }
    }
    const edit = record => {
      router.push({
        name: 'modEdit',
        params: { id: record.id, templateId: record.templateId }
      })
    }
    const editDetail = (record, key) => {
      state.key = key
      state.editValue = record[key]
      state.data = record
      state.editStatus = true
    }
    const getdDetailList = () => {
      state.childLoading = true
      detailList({ pageId: state.pageId })
        .then(res => {
          if (res.code === 10000) {
            state.childLoading = false
            res.data.forEach(item => {
              for (const key in item) {
                if (item[key] === '') item[key] = '无'
              }
            })
            state.childDataSoure = res.data
          }
        }).catch(err => console.log(err))
    }
    const setUp = record => {
      state.pageId = record.id
      state.visible = true
      state.channel = record.channel.label
      getdDetailList()
    }
    const save = record => {
      saveDetail(record)
        .then(res => {
          if (res.code === 10000) message.success(res.msg)
        }).catch(err => console.log(err))
    }
    const deleteBtn = record => {
      deletePage({ pageId: record.id })
        .then(res => {
          if (res.code === 10000) {
            message.success('删除' + res.msg)
            loadData()
          }
        }).catch(err => console.log(err))
    }
    const generate = record => {
      if (record.pageUrl === '' || record.pageUrl.includes('无') || record.pageUrl === null || record.pageUrl === undefined) message.error('页面地址不能为空')
      else {
        generatePage({ pageDeTailId: record.id })
          .then(res => {
            if (res.code === 10000) message.success(res.msg)
          }).catch(err => console.log(err))
      }
    }
    const batchGener = () => {
      if (state.batch.length <= 0) message.error('您还未选择任何子页面')
      else {
        generatePage({ pageDeTailIds: state.batch })
          .then(res => {
            if (res.code === 10000) message.success(res.msg)
          }).catch(err => console.log(err))
      }
    }
    const addChannel = () => {
      state.addLoading = true
      // state.channelList.forEach(item => {
      //   if (item.value === state.channel) {
      //     state.channel = item
      //     console.log(state.channel)
      //   }
      // })
      addPage({
        channelId: state.channel,
        templateId: state.id
      }).then(res => {
        if (res.code === 10000) {
          state.addStatus = false
          state.channelLoading = false
          state.channel = null
          message.success('配置新页面' + res.msg)
          loadData()
        }
      }).catch(err => console.log(err))
        .finally(() => { state.addLoading = false })
    }
    const confirm = () => {
      state.editStatus = false
      state.childDataSoure.forEach(item => {
        if (item.id === state.data.id) {
          item[state.key] = state.editValue
          item = state.data
        }
      })
    }
    const useStatusChange = (e, val) => {
      editSwitch({ pageDetailId: e.id })
        .then(res => {
          if (res.code === 10000) message.success(res.msg)
        }).catch(err => console.log(err))
    }
    const childChange = (e, val) => {
      console.log(e, val)
    }
    const onUpdate = () => {
      update({ pageId: state.pageId })
        .then(res => {
          if (res.code === 10000) {
            getdDetailList()
            message.success('更新' + res.msg)
          }
        }).catch(err => { console.log(err) })
    }
    const personalChange = record => {
      if (record.personalInfoAuth.value === 1) record.personalInfoAuth.label = '是'
      else record.personalInfoAuth.label = '否'
    }

    return {
      ...toRefs(state),
      edit,
      save,
      setUp,
      confirm,
      newPage,
      generate,
      onUpdate,
      deleteBtn,
      batchGener,
      editDetail,
      addChannel,
      childChange,
      personalChange,
      useStatusChange
    }
  }
}
</script>

<style lang="less" scoped>
.ant-radio-group {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
</style>
